import { Title } from "@solidjs/meta";
import { action, redirect } from "@solidjs/router";
import { createId } from "@paralleldrive/cuid2";
import { useAppState } from "~/AppContext";
import { create as createKnowAct } from "~/services/knows";
import KnowForm from "~/components/KnowForm";

const toSubmit = action(async (formData: FormData) => {
  const essence = formData.get("essence")?.toString() || "";
  const body = formData.get("body")?.toString() || "";
  const id = createId();
  await createKnowAct({ id, essence, body });
  throw redirect(`/n/${id}`);
}, "n/:id/edit ");

export default function Submit() {
  const { t } = useAppState();

  return (
    <main class="max-w-screen-xl mx-auto p-4 w-full">
      <Title>{t("app.title")}</Title>
      <KnowForm action={toSubmit} data={() => ({})} />
    </main>
  );
}
